// src/components/skip-link.ts
import {
  cssVar,
  defineStyle,
  defineStyleConfig
} from "@chakra-ui/styled-system";
var $bg = cssVar("skip-link-bg");
var baseStyle = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  _focusVisible: {
    boxShadow: "outline",
    padding: "4",
    position: "fixed",
    top: "6",
    insetStart: "6",
    [$bg.variable]: "colors.white",
    _dark: {
      [$bg.variable]: "colors.gray.700"
    },
    bg: $bg.reference
  }
});
var skipLinkTheme = defineStyleConfig({
  baseStyle
});

export {
  skipLinkTheme
};
