import {
  typography_default
} from "./chunk-HOOF7HHD.mjs";
import {
  inputTheme
} from "./chunk-WFLWAEKG.mjs";
import {
  runIfFn
} from "./chunk-EBS47JIL.mjs";

// src/components/number-input.ts
import { numberInputAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle
} from "@chakra-ui/styled-system";
import { calc, cssVar } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);
var $stepperWidth = cssVar("number-input-stepper-width");
var $inputPadding = cssVar("number-input-input-padding");
var inputPaddingValue = calc($stepperWidth).add("0.5rem").toString();
var $bg = cssVar("number-input-bg");
var $fg = cssVar("number-input-color");
var $border = cssVar("number-input-border-color");
var baseStyleRoot = defineStyle({
  [$stepperWidth.variable]: "sizes.6",
  [$inputPadding.variable]: inputPaddingValue
});
var baseStyleField = defineStyle(
  (props) => {
    var _a, _b;
    return (_b = (_a = runIfFn(inputTheme.baseStyle, props)) == null ? void 0 : _a.field) != null ? _b : {};
  }
);
var baseStyleStepperGroup = defineStyle({
  width: $stepperWidth.reference
});
var baseStyleStepper = defineStyle({
  borderStart: "1px solid",
  borderStartColor: $border.reference,
  color: $fg.reference,
  bg: $bg.reference,
  [$fg.variable]: "colors.chakra-body-text",
  [$border.variable]: "colors.chakra-border-color",
  _dark: {
    [$fg.variable]: "colors.whiteAlpha.800",
    [$border.variable]: "colors.whiteAlpha.300"
  },
  _active: {
    [$bg.variable]: "colors.gray.200",
    _dark: {
      [$bg.variable]: "colors.whiteAlpha.300"
    }
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  }
});
var baseStyle = definePartsStyle((props) => {
  var _a;
  return {
    root: baseStyleRoot,
    field: (_a = runIfFn(baseStyleField, props)) != null ? _a : {},
    stepperGroup: baseStyleStepperGroup,
    stepper: baseStyleStepper
  };
});
function getSize(size) {
  var _a, _b, _c;
  const sizeStyle = (_a = inputTheme.sizes) == null ? void 0 : _a[size];
  const radius = {
    lg: "md",
    md: "md",
    sm: "sm",
    xs: "sm"
  };
  const _fontSize = (_c = (_b = sizeStyle.field) == null ? void 0 : _b.fontSize) != null ? _c : "md";
  const fontSize = typography_default.fontSizes[_fontSize];
  return definePartsStyle({
    field: {
      ...sizeStyle.field,
      paddingInlineEnd: $inputPadding.reference,
      verticalAlign: "top"
    },
    stepper: {
      fontSize: calc(fontSize).multiply(0.75).toString(),
      _first: {
        borderTopEndRadius: radius[size]
      },
      _last: {
        borderBottomEndRadius: radius[size],
        mt: "-1px",
        borderTopWidth: 1
      }
    }
  });
}
var sizes = {
  xs: getSize("xs"),
  sm: getSize("sm"),
  md: getSize("md"),
  lg: getSize("lg")
};
var numberInputTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});

export {
  numberInputTheme
};
