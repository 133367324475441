// src/object.ts
import { default as default2 } from "lodash.mergewith";
function omit(object, keys) {
  const result = {};
  Object.keys(object).forEach((key) => {
    if (keys.includes(key))
      return;
    result[key] = object[key];
  });
  return result;
}
function pick(object, keys) {
  const result = {};
  keys.forEach((key) => {
    if (key in object) {
      result[key] = object[key];
    }
  });
  return result;
}
function split(object, keys) {
  const picked = {};
  const omitted = {};
  Object.keys(object).forEach((key) => {
    if (keys.includes(key)) {
      picked[key] = object[key];
    } else {
      omitted[key] = object[key];
    }
  });
  return [picked, omitted];
}
function get(obj, path, fallback, index) {
  const key = typeof path === "string" ? path.split(".") : [path];
  for (index = 0; index < key.length; index += 1) {
    if (!obj)
      break;
    obj = obj[key[index]];
  }
  return obj === void 0 ? fallback : obj;
}
var memoize = (fn) => {
  const cache = /* @__PURE__ */ new WeakMap();
  const memoizedFn = (obj, path, fallback, index) => {
    if (typeof obj === "undefined") {
      return fn(obj, path, fallback);
    }
    if (!cache.has(obj)) {
      cache.set(obj, /* @__PURE__ */ new Map());
    }
    const map = cache.get(obj);
    if (map.has(path)) {
      return map.get(path);
    }
    const value = fn(obj, path, fallback, index);
    map.set(path, value);
    return value;
  };
  return memoizedFn;
};
var memoizedGet = memoize(get);
function getWithDefault(path, scale) {
  return memoizedGet(scale, path, path);
}
function objectFilter(object, fn) {
  const result = {};
  Object.keys(object).forEach((key) => {
    const value = object[key];
    const shouldPass = fn(value, key, object);
    if (shouldPass) {
      result[key] = value;
    }
  });
  return result;
}
var filterUndefined = (object) => objectFilter(object, (val) => val !== null && val !== void 0);
var objectKeys = (obj) => Object.keys(obj);
var fromEntries = (entries) => entries.reduce((carry, [key, value]) => {
  carry[key] = value;
  return carry;
}, {});
var getCSSVar = (theme, scale, value) => {
  var _a, _b, _c;
  return (_c = (_b = (_a = theme.__cssMap) == null ? void 0 : _a[`${scale}.${value}`]) == null ? void 0 : _b.varRef) != null ? _c : value;
};

export {
  omit,
  pick,
  split,
  get,
  memoize,
  memoizedGet,
  getWithDefault,
  objectFilter,
  filterUndefined,
  objectKeys,
  fromEntries,
  getCSSVar,
  default2 as default
};
