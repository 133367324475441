import {
  useTheme
} from "./chunk-NLMMK76H.mjs";

// src/hooks.ts
import { useColorMode } from "@chakra-ui/color-mode";
function useChakra() {
  const colorModeResult = useColorMode();
  const theme = useTheme();
  return { ...colorModeResult, theme };
}
function getBreakpointValue(theme, value, fallback) {
  var _a, _b;
  if (value == null)
    return value;
  const getValue = (val) => {
    var _a2, _b2;
    return (_b2 = (_a2 = theme.__breakpoints) == null ? void 0 : _a2.asArray) == null ? void 0 : _b2[val];
  };
  return (_b = (_a = getValue(value)) != null ? _a : getValue(fallback)) != null ? _b : fallback;
}
function getTokenValue(theme, value, fallback) {
  var _a, _b;
  if (value == null)
    return value;
  const getValue = (val) => {
    var _a2, _b2;
    return (_b2 = (_a2 = theme.__cssMap) == null ? void 0 : _a2[val]) == null ? void 0 : _b2.value;
  };
  return (_b = (_a = getValue(value)) != null ? _a : getValue(fallback)) != null ? _b : fallback;
}
function useToken(scale, token, fallback) {
  const theme = useTheme();
  return getToken(scale, token, fallback)(theme);
}
function getToken(scale, token, fallback) {
  const _token = Array.isArray(token) ? token : [token];
  const _fallback = Array.isArray(fallback) ? fallback : [fallback];
  return (theme) => {
    const fallbackArr = _fallback.filter(Boolean);
    const result = _token.map((token2, index) => {
      var _a, _b;
      if (scale === "breakpoints") {
        return getBreakpointValue(theme, token2, (_a = fallbackArr[index]) != null ? _a : token2);
      }
      const path = `${scale}.${token2}`;
      return getTokenValue(theme, path, (_b = fallbackArr[index]) != null ? _b : token2);
    });
    return Array.isArray(token) ? result : result[0];
  };
}

export {
  useChakra,
  useToken,
  getToken
};
