// src/Check.tsx
import { createIcon } from "@chakra-ui/icon";
import { jsx } from "react/jsx-runtime";
var CheckIcon = createIcon({
  viewBox: "0 0 14 14",
  path: /* @__PURE__ */ jsx("g", { fill: "currentColor", children: /* @__PURE__ */ jsx("polygon", { points: "5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" }) })
});

export {
  CheckIcon
};
